.new-item-form {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  width: 100%;
}

@media screen and (min-width: 600px) {
  .new-item-form {
    max-width: 600px;
  }
}

.new-item-text-box {
  flex-grow: 1;
  height: 30px;
  border-radius: 0px;
  border: 1px solid #f2f2f2;
  background-color: #fcfcfc;
  font-size: 14px;
  padding-left: 10px;
}

.new-item-submit {
  height: 32px;
  margin: auto;
  border-radius: 0px;
  border: 1px solid rgb(235, 235, 255);
  background-color: #196ad0;
  color: rgb(235, 235, 255);
  cursor: pointer;
  /* display: none; */
}