.FrontPage {
  text-align: center;
}

.ListListWrapper {
  display: flex;
  font-size: 1em;
  align-items: center;
  padding: 0.15em;
  border-top: 1px solid #ddd
}

a.ListLink {
  font-size: 1em;
  color: #0070ff;
  text-decoration: none;
}

a:hover.ListLink {
  text-decoration: underline;
}