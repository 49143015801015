.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  color: #196ad0;
  font-size: 16px;
}

.App-link {
  color: #61dafb;
}

#root {
  max-width: 600px;
  width: 90%;
  margin: 0 auto;
  display: block;
  padding-top: 10px;
}

.item-list {
  text-align: left;
  display: block;
  padding: 0px;
}

.back-button {
  font-size: 1em;
  margin-top: 0.5em;
  float: left;
  padding-left: 8px;
  color: #196ad0;
}

.status-icon {
  font-size: 1em;
  margin-top: 0.5em;
  float: right;
  padding-right: 8px;
  color: #ccc;
}

@media screen and (min-width: 600px) {
  .item-list {
    max-width: 600px;
  }
}

ul {
  padding: 0px;
  list-style: none;  
  align-items: center;
}

li.list-item {
  display: flex;
  align-items: center;
  background-color: #fbfbfb;
  color: #111111;
  border-top: 1px solid rgb(235, 235, 255);
  padding: 4px;
  margin: 0;
}

li.list-item:last-child {
  border-bottom: 1px solid rgb(235, 235, 255);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.float-right {
  float: right;
}

.pad-left {
  margin-left: 15px;
}

.set-flex {
  display: flex;
}

.flex-grow {
  flex-grow: 1;
}

a.fa-btn {
  background-color: DodgerBlue; /* Blue background */
  border: none; /* Remove borders */
  color: white; /* White text */
  padding: 3px 6px; /* Some padding */
  /* cursor: pointer; Mouse pointer on hover */
}

/* Darker background on mouse-over */
.fa-btn:hover {
  background-color: RoyalBlue;
}

.btn {
  border: none; /* Remove borders */
  padding: 8px; /* Some padding */
  text-decoration: none;
  font-size: 0.8em;
  cursor: pointer;
}

.btn-green {
  background-color: #56c25c; /* Blue background */
  color: white; /* White text */
}

/* Darker background on mouse-over */
.btn-green:hover {
  background-color: #30cc30;
}

.btn-red {
  background-color: #f77171; /* Blue background */
  color: white; /* White text */
}

/* Darker background on mouse-over */
.btn-red:hover {
  background-color: #cc3030;
}

.btn-blue {
  background-color: dodgerblue; /* Blue background */
  color: white; /* White text */
}

/* Darker background on mouse-over */
.btn-blue:hover {
  background-color: royalblue;
}

.icon-red {
  color: #f77171
}

.SocketStateDiv {
  margin: 0px;
  padding: 0px;
  font-size: smaller;
}

.hidden {
  visibility: hidden;
}